<template>
  <div class="TaskEditor">
    <el-form
      :disabled="localTask.loading"
      @keyup.esc.native="closeEditor"
    >
      <el-row>
        <el-col :span="18">
          <div class="flex-container">
            <el-col :span="23">
              <el-input
                ref="description"
                v-model="localTask.description"
                :class="{ 'has-error': $v.localTask.description.$error }"
                placeholder="Enter description"
                prefix-icon="el-icon-edit-outline"
                @input="$v.localTask.description.$touch()"
                @keyup.enter.native="localTask.exists() ? updateTask() : addTask()"
              />
              <div
                v-if="$v.localTask.description.$error"
                class="errors"
              >
                <span
                  v-if="!$v.localTask.description.required"
                  class="error-message"
                >
                  Field is required
                </span>
              </div>
            </el-col>
          </div>
        </el-col>
        <el-col
          :span="6"
        >
          <el-select
            v-model="localTask.project"
            value-key="uuid"
            :disabled="projects.isEmpty()"
          >
            <el-option
              v-for="project in projects.all()"
              :key="project.uuid"
              :label="project.name"
              value-key="uuid"
              :value="project"
            />
          </el-select>
          <div
            v-if="$v.localTask.project.uuid.$error"
            class="errors"
          >
            <span
              v-if="!$v.localTask.project.uuid.required"
              class="error-message"
            >
              This field is required
            </span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-input-number
            v-model="localTask.hours"
            :min="0"
            controls-position="right"
            @keyup.enter.native="(editingTask) ? updateTask() : addTask()"
          />
          <span class="hours gray-text">
            h
          </span>
          <el-input-number
            v-model="localTask.minutes"
            :min="0"
            :max="59"
            controls-position="right"
            @keyup.enter.native="localTask.exists() ? updateTask() : addTask()"
          />
          <span class="minutes gray-text">
            m
          </span>
        </el-col>
      </el-row>
      <el-row class="actions">
        <el-col class="action-buttons">
          <el-button
            :disabled="formInvalid"
            :loading="localTask.loading"
            type="success"
            size="middle"
            title="Save editing"
            @click.prevent="localTask.exists() ? updateTask() : addTask()"
          >
            Save
          </el-button>
          <el-button
            type="plain"
            size="middle"
            @click.prevent="closeEditor"
          >
            Cancel
          </el-button>

          <el-button
            v-if="localTask.exists()"
            type="text"
            class="delete_button"
            @click.prevent="deleteTask"
          >
            Delete Task
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <div
      v-if="localTask.loading"
      key="task-placeholder"
      class="task-placeholder"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';

import { Task, ProjectRepository } from '@timeragent/core';

export default {
  props: {
    task: {
      type: Task,
      required: false,
      default: () => null,
    },
  },

  data() {
    return {
      localTask: this.task ? this.task.clone() : new Task(),
      projectRepository: new ProjectRepository(),
    };
  },

  computed: {
    ...mapGetters([
      'user',
      'date',
    ]),
    formInvalid() {
      return this.$v.$invalid;
    },
    projects() {
      return this.projectRepository.dataset;
    },
  },

  mounted() {
    this.$refs.description.focus();
    this.projectRepository.many({
      withTasks: false,
      filters: {
        organizationUuid: this.organizationUuid,
      },
    });
  },

  methods: {
    closeEditor() {
      this.localTask = { ...this.oldTask };
      this.$emit('close-editor');
    },
    updateTask() {
      if (this.formInvalid) return;
      this.$emit('update-task', this.localTask);
    },
    addTask() {
      if (this.formInvalid) return;
      this.localTask.createdAt = moment(this.date).toISOString();
      // this.localTask.createdAt = `${moment(this.date).format('YYYY-MM-DD')}
      // ${moment().format('H:mm:ss')}`;

      // this.localTask.tags = this.localTask.tags.dataset.all();

      // Set current selected date to the task
      this.localTask.createdAt = this.date;

      this.$emit('add-task', this.localTask);
    },
    deleteTask() {
      this.$emit('delete-task');
    },
  },
  validations: {
    localTask: {
      description: {
        required,
      },
      project: {
        uuid: {
          required,
        },
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style
  lang="stylus"
  scoped
>

  .TaskEditor
    margin 0 20px

  .el-icon-edit-outline
    font-size 20px
    margin 10px

  .el-select
    width 100%

  .actions
    margin-top 20px

  .el-row
    margin-bottom 30px

  .hours, .minutes
    padding-right 10px
    padding-left 10px

  .task-placeholder
    height 10px
    animation-duration 1.7s
    animation-fill-mode forwards
    animation-iteration-count infinite
    animation-name placeholderAnimate
    animation-timing-function linear
    background linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%)
    background-size 1300px

  @keyframes placeholderAnimate
    0%
      background-position: -650px 0
    100%
      background-position: 650px 0

</style>
